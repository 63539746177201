import { cloneDeep } from "es-toolkit";

const Classic352: ClassicFormation<"3-5-2"> = {
  name: "3-5-2",
  module: [
    [["D"], ["D"], ["D"]],
    [["C"], ["C"], ["C"], ["C"], ["C"]],
    [["A"], ["A"]],
  ],
  players: [
    [null, null, null],
    [null, null, null, null, null],
    [null, null],
  ],
};
const Classic343: ClassicFormation<"3-4-3"> = {
  name: "3-4-3",
  module: [
    [["D"], ["D"], ["D"]],
    [["C"], ["C"], ["C"], ["C"]],
    [["A"], ["A"], ["A"]],
  ],
  players: [
    [null, null, null],
    [null, null, null, null],
    [null, null, null],
  ],
};
const Classic451: ClassicFormation<"4-5-1"> = {
  name: "4-5-1",
  module: [[["D"], ["D"], ["D"], ["D"]], [["C"], ["C"], ["C"], ["C"], ["C"]], [["A"]]],
  players: [[null, null, null, null], [null, null, null, null, null], [null]],
};
const Classic442: ClassicFormation<"4-4-2"> = {
  name: "4-4-2",
  module: [
    [["D"], ["D"], ["D"], ["D"]],
    [["C"], ["C"], ["C"], ["C"]],
    [["A"], ["A"]],
  ],
  players: [
    [null, null, null, null],
    [null, null, null, null],
    [null, null],
  ],
};
const Classic433: ClassicFormation<"4-3-3"> = {
  name: "4-3-3",
  module: [
    [["D"], ["D"], ["D"], ["D"]],
    [["C"], ["C"], ["C"]],
    [["A"], ["A"], ["A"]],
  ],
  players: [
    [null, null, null, null],
    [null, null, null],
    [null, null, null],
  ],
};
const Classic532: ClassicFormation<"5-3-2"> = {
  name: "5-3-2",
  module: [
    [["D"], ["D"], ["D"], ["D"], ["D"]],
    [["C"], ["C"], ["C"]],
    [["A"], ["A"]],
  ],
  players: [
    [null, null, null, null, null],
    [null, null, null],
    [null, null],
  ],
};
const Classic541: ClassicFormation<"5-4-1"> = {
  name: "5-4-1",
  module: [[["D"], ["D"], ["D"], ["D"], ["D"]], [["C"], ["C"], ["C"], ["C"]], [["A"]]],
  players: [[null, null, null, null, null], [null, null, null, null], [null]],
};
export const classicFormations = [
  Classic352,
  Classic343,
  Classic451,
  Classic442,
  Classic433,
  Classic532,
  Classic541,
];

const Mantra343: MantraFormation<"3-4-3"> = {
  name: "3-4-3",
  module: [
    [["Dc"], ["Dc"], ["Dc", "B"]],
    [["E"], ["M", "C"], ["C"], ["E"]],
    [
      ["W", "A"],
      ["A", "Pc"],
      ["W", "A"],
    ],
  ],
  players: [
    [null, null, null],
    [null, null, null, null],
    [null, null, null],
  ],
};
const Mantra3412: MantraFormation<"3-4-1-2"> = {
  name: "3-4-1-2",
  module: [
    [["Dc"], ["Dc"], ["Dc", "B"]],
    [["E"], ["M", "C"], ["C"], ["E"]],
    [["T"]],
    [
      ["A", "Pc"],
      ["A", "Pc"],
    ],
  ],
  players: [[null, null, null], [null, null, null, null], [null], [null, null]],
};
const Mantra3421: MantraFormation<"3-4-2-1"> = {
  name: "3-4-2-1",
  module: [
    [["Dc"], ["Dc"], ["Dc", "B"]],
    [["E", "W"], ["M"], ["M", "C"], ["E"]],
    [["T"], ["T", "A"]],
    [["A", "Pc"]],
  ],
  players: [[null, null, null], [null, null, null, null], [null, null], [null]],
};
const Mantra352: MantraFormation<"3-5-2"> = {
  name: "3-5-2",
  module: [
    [["Dc"], ["Dc"], ["Dc", "B"]],
    [["E", "W"], ["M", "C"], ["M"], ["C"], ["E"]],
    [
      ["A", "Pc"],
      ["A", "Pc"],
    ],
  ],
  players: [
    [null, null, null],
    [null, null, null, null, null],
    [null, null],
  ],
};
const Mantra3511: MantraFormation<"3-5-1-1"> = {
  name: "3-5-1-1",
  module: [
    [["Dc"], ["Dc"], ["Dc", "B"]],
    [["E", "W"], ["M"], ["C"], ["M"], ["E", "W"]],
    [["T", "A"]],
    [["A", "Pc"]],
  ],
  players: [[null, null, null], [null, null, null, null, null], [null], [null]],
};
const Mantra433: MantraFormation<"4-3-3"> = {
  name: "4-3-3",
  module: [
    [["Dd"], ["Dc"], ["Dc"], ["Ds"]],
    [["M", "C"], ["M"], ["C"]],
    [
      ["W", "A"],
      ["A", "Pc"],
      ["W", "A"],
    ],
  ],
  players: [
    [null, null, null, null],
    [null, null, null],
    [null, null, null],
  ],
};
const Mantra4312: MantraFormation<"4-3-1-2"> = {
  name: "4-3-1-2",
  module: [
    [["Dd"], ["Dc"], ["Dc"], ["Ds"]],
    [["M", "C"], ["M"], ["C"]],
    [["T"]],
    [
      ["T", "A", "Pc"],
      ["A", "Pc"],
    ],
  ],
  players: [[null, null, null, null], [null, null, null], [null], [null, null]],
};
const Mantra442: MantraFormation<"4-4-2"> = {
  name: "4-4-2",
  module: [
    [["Dd"], ["Dc"], ["Dc"], ["Ds"]],
    [["E", "W"], ["M", "C"], ["C"], ["E"]],
    [
      ["A", "Pc"],
      ["A", "Pc"],
    ],
  ],
  players: [
    [null, null, null, null],
    [null, null, null, null],
    [null, null],
  ],
};
const Mantra4141: MantraFormation<"4-1-4-1"> = {
  name: "4-1-4-1",
  module: [
    [["Dd"], ["Dc"], ["Dc"], ["Ds"]],
    [["M"]],
    [["E", "W"], ["C", "T"], ["T"], ["W"]],
    [["A", "Pc"]],
  ],
  players: [[null, null, null, null], [null], [null, null, null, null], [null]],
};
const Mantra4411: MantraFormation<"4-4-1-1"> = {
  name: "4-4-1-1",
  module: [
    [["Dd"], ["Dc"], ["Dc"], ["Ds"]],
    [["E", "W"], ["M"], ["C"], ["E", "W"]],
    [["T", "A"]],
    [["A", "Pc"]],
  ],
  players: [[null, null, null, null], [null, null, null, null], [null], [null]],
};
const Mantra4231: MantraFormation<"4-2-3-1"> = {
  name: "4-2-3-1",
  module: [
    [["Dd"], ["Dc"], ["Dc"], ["Ds"]],
    [["M"], ["M", "C"]],
    [["W", "T"], ["T"], ["W", "A"]],
    [["A", "Pc"]],
  ],
  players: [[null, null, null, null], [null, null], [null, null, null], [null]],
};
export const mantraFormations = [
  Mantra343,
  Mantra3412,
  Mantra3421,
  Mantra352,
  Mantra3511,
  Mantra433,
  Mantra4312,
  Mantra442,
  Mantra4141,
  Mantra4411,
  Mantra4231,
];

/**
 * @param roles
 * @param formationRoles
 * @returns true if a player fits the given roles
 */
export const mantraRolesFitFormationRole = (roles: RoleMantra[], formationRoles: RoleMantra[]) => {
  for (const role of roles) {
    if (formationRoles.includes(role)) {
      return true;
    }
  }
  return false;
};

/**
 * @param role
 * @param formationRoles
 * @returns true if a player fits the given roles
 */
export const classicRoleFitFormationRole = (role: RoleClassic, formationRoles: RoleClassic[]) => {
  if (formationRoles.includes(role)) {
    return true;
  }

  return false;
};

/**
 * calcFormation returns the best possible selection of players that fit
 * a given formation
 * @param players sorted list of players
 * @param formation
 * @returns a formation filled with the best players for each position
 */
export const calcFormation =
  <T extends GameMode>(gameMode: T) =>
  (players: Player[], formation: Formation) => {
    const remainingPlayers = [...players];
    const filledFormation: Formation = cloneDeep(formation);
    let value = 0;
    for (const [lineIDX, line] of formation.module.entries()) {
      for (const [roleIDX, role] of line.entries()) {
        const playerIDX = remainingPlayers.findIndex(p =>
          gameMode === "classic"
            ? classicRoleFitFormationRole(p.roleClassic, role as RoleClassic[])
            : mantraRolesFitFormationRole(p.rolesMantra, role as RoleMantra[]),
        );
        if (playerIDX !== -1) {
          const player = remainingPlayers.splice(playerIDX, 1)[0];
          filledFormation.players![lineIDX][roleIDX] = player;
          value += player.amount ?? 0;
        }
      }
    }
    return { formation: filledFormation, value: value };
  };

export function formationsFromGameMode(gameMode: "mantra"): MantraFormation[];
export function formationsFromGameMode(gameMode: "classic"): ClassicFormation[];
export function formationsFromGameMode(gameMode: GameMode): ClassicFormation[] | MantraFormation[];
export function formationsFromGameMode(gameMode: GameMode) {
  return gameMode === "classic" ? classicFormations : mantraFormations;
}

/**
 * findBestFormation calculates a formation using all possible formations and returns
 * the best one given the value of players
 * @param players sorted list of players
 * @returns the best possible formation out of all available formations
 */
export function findBestFormation(players: Player[], gameMode: GameMode) {
  let highestValueFormation: Formation | null = null;
  let highestValue = 0;
  const formations = formationsFromGameMode(gameMode);
  for (const f of formations) {
    const c = calcFormation(gameMode)(players, f);
    if (c.value > highestValue) {
      highestValueFormation = c.formation;
      highestValue = c.value;
    }
  }
  return highestValueFormation;
}
