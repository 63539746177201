import { t } from "@/locales/translate";
import { Listbox } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { cx } from "classix";
import { Fragment, useState } from "react";

import Formation from "@/components/Formation";
import { calcFormation, findBestFormation, formationsFromGameMode } from "@/lib/formations";
import { sortByAmount } from "@/lib/sortPlayers";
import { useLiveAuctionStore } from "@/stores/liveAuctionStore";

type Props = {
  players: Player[];
};

function CalculateFormation(props: Props) {
  const gameMode = useLiveAuctionStore(state => state.gameMode);
  const formations = formationsFromGameMode(gameMode);
  const { players } = props;
  const [selectedFormation, setSelectedFormation] = useState(formations[0]);
  const sortedByAmount = players.slice().sort(sortByAmount);
  const calculatedFormation = calcFormation(gameMode)(sortedByAmount, selectedFormation);

  function handleAutoClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    const b = findBestFormation(sortedByAmount, gameMode);
    setSelectedFormation(b || formations[0]);
  }

  return (
    <div className="relative">
      <div className="relative z-10">
        <Listbox value={selectedFormation} onChange={setSelectedFormation}>
          <Listbox.Label>
            <p className="p-2">{t("calculator.label")}</p>
          </Listbox.Label>
          <div className="flex justify-between space-x-2">
            <Listbox.Button className="focus:ring-brand dark:focus:ring-brand-dark relative w-full cursor-pointer rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus:ring focus:ring-opacity-50 dark:bg-neutral-600">
              <span className="block truncate">{selectedFormation.name}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-neutral-400" aria-hidden="true" />
              </span>
            </Listbox.Button>
            <button
              onClick={handleAutoClick}
              className="focus:ring-brand dark:focus:ring-brand-dark rounded-md bg-white px-2 py-1 text-sm uppercase shadow-md hover:shadow focus:outline-none focus:ring focus:ring-opacity-50 dark:bg-neutral-600"
            >
              {t("calculator.submit")}
            </button>
          </div>
          <Listbox.Options className="focus:ring-brand dark:focus:ring-brand-dark absolute z-0 mt-4 w-full rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-opacity-50">
            {formations.map(formation => (
              <Listbox.Option key={formation.name} value={formation} as={Fragment}>
                {({ active, selected }) => (
                  <li
                    className={cx(
                      "flex cursor-pointer items-center justify-between px-2 py-1 first:rounded-t-lg last:rounded-b-lg",
                      active
                        ? "text-brand bg-blue-100 dark:bg-blue-800 dark:text-white"
                        : "bg-white dark:bg-neutral-600",
                    )}
                  >
                    <span>{formation.name}</span>
                    {selected && <CheckIcon className="h-4 w-4" />}
                  </li>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Listbox>
      </div>
      <Formation
        formation={calculatedFormation.formation}
        value={calculatedFormation.value}
        className="absolute top-0 z-0 w-full"
      />
    </div>
  );
}
export default CalculateFormation;
