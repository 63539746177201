import { t } from "@/locales/translate";
import { cx } from "classix";

import { PlayerRole } from "@/components/PlayerRole";

function Formation(props: { formation: Formation; value: number; className?: string }) {
  const { formation, value, className } = props;
  return (
    <div className={cx("relative space-y-8 py-8", className)}>
      {formation.players?.map((players, lineIDX) => (
        <div key={lineIDX} className="flex items-center justify-evenly">
          {players.map((p, roleIDX) => (
            <div key={p !== null ? p.id : roleIDX} className="flex flex-col justify-center">
              {p !== null ? (
                <>
                  <span className="whitespace-nowrap text-center text-xs">{p?.name}</span>
                  <div className="flex items-center justify-center space-x-0.5">
                    {formation.module[lineIDX][roleIDX].map(r => (
                      <PlayerRole key={r} role={r} compact={true} className="w-7 text-sm" />
                    ))}
                  </div>
                </>
              ) : (
                <div className="flex items-center justify-center space-x-0.5 pt-6">
                  {formation.module[lineIDX][roleIDX].map(r => (
                    <PlayerRole
                      key={r}
                      role={r}
                      compact={true}
                      className="w-7 text-sm opacity-60"
                    />
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      ))}
      <div className="text-center text-sm uppercase">{t("formationValue", { value })}</div>
    </div>
  );
}
export default Formation;
